/* eslint-disable react/style-prop-object */
import React from "react";
import { useEffect } from 'react';

const { kakao } = window;

export const About = (props) => {

  useEffect(()=>{
    var container = document.getElementById('map');
    var options = {
      center: new kakao.maps.LatLng(37.4776190161713, 126.88905533739),
      level: 3
    };

    var kakaoMap = new kakao.maps.Map(container, options);

    var markerPosition  = new kakao.maps.LatLng(37.4776190161713, 126.88905533739);

    var imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png';
    var imageSize = new kakao.maps.Size(64, 69);
    var imageOption = {offset: new kakao.maps.Point(27, 69)}; 
    var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
      
    var marker = new kakao.maps.Marker({
      position: markerPosition,
      image: markerImage
    });

    marker.setMap(null);
    marker.setMap(kakaoMap);

  }, []);

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-6 col-md-6">            
            <div className="about-text">
              <h2>회사소개</h2>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <h3>개요</h3>
              <div className="list-style">
                <div className="col-lg-4 col-sm-4 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>                
              </div>
            </div>
            <div className="about-text2">              
              <div className="list-style">                
                <div className="col-lg-48 col-sm-48 col-xs-48">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d} </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <p></p>
          <div className="col-xs-12 col-md-12">
            <h3>오시는길</h3>
            <div id="map" className="map"></div>
          </div>
        </div>
      </div>

    </div>
  );
};
